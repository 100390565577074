var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-sm-6 white",attrs:{"cols":"12"}},[_c('div',{staticClass:"px-4 pt-4 pb-0 pb-sm-4"},[_c('v-text-field',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1)]),_c('v-col',{staticClass:"col-sm-6 white",attrs:{"cols":"12"}},[_c('div',{staticClass:"pa-4 pl-sm-0"},[_c('v-select',{staticClass:"rounded-0",attrs:{"items":[{ name: 'All Status', value: null }, { name: 'No Status', value: 0 }, { name: 'Verified', value: 1 }],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":"","label":"Status"},model:{value:(_vm.tableSearchStatus),callback:function ($$v) {_vm.tableSearchStatus=$$v},expression:"tableSearchStatus"}})],1)]),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.table,"item-key":"id","server-items-length":_vm.tableTotal,"page":_vm.tablePage,"items-per-page":_vm.tableLimit,"options":_vm.options,"footer-props":{
          itemsPerPageOptions: [25, 50, 1000]
        },"loading":_vm.tableLoading,"loading-text":"Loading... Please wait"},on:{"update:page":function($event){_vm.tablePage=$event},"update:itemsPerPage":function($event){_vm.tableLimit=$event},"update:items-per-page":function($event){_vm.tableLimit=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.$localDT(item.created, 'display'))+" ")])])]}},{key:"item.customer_name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.customer_name)+" "),_c('div',{staticClass:"caption",staticStyle:{"line-height":"120%"}},[_vm._v(" E : "+_vm._s(item.customer_email)),_c('br'),_vm._v(" P : "+_vm._s(item.customer_phone)+" ")])])]}},{key:"item.notes",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.notes)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(!parseInt(item.status) ? 'No Status' : 'Verified')+" ")])]}},{key:"item.option",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"100px"}},[(_vm.$role(['manager'], _vm.$store.state.user.role))?_c('v-btn',{staticClass:"rounded-0 text-capitalize",attrs:{"depressed":"","small":"","dark":"","color":"orange"},on:{"click":function($event){$event.preventDefault();return _vm.TOUR_GUIDE_SHOW(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Detail ")],1):_vm._e()],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"340"},model:{value:(_vm.dialogTourGuide.show),callback:function ($$v) {_vm.$set(_vm.dialogTourGuide, "show", $$v)},expression:"dialogTourGuide.show"}},[(_vm.dialogTourGuide.data)?_c('v-card',[_c('v-card-title',{staticClass:"body-1 justify-center"},[_vm._v(" "+_vm._s(_vm.dialogTourGuide.data.id ? 'Update Banner' : 'Create New Banner')+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-2",staticStyle:{"max-height":"75vh"}},[_c('a',{staticClass:"d-block pb-4",class:[_vm.dialogTourGuide.data.selfie ? '' : 'disabled'],staticStyle:{"position":"relative"},attrs:{"href":_vm.dialogTourGuide.data.selfie || '#',"target":"_blank"}},[_c('v-img',{staticClass:"blue lighten-4 fill-width",attrs:{"src":_vm.dialogTourGuide.data.selfie,"aspect-ratio":"1"}})],1),_c('v-textarea',{staticClass:"mb-4 rounded-0",attrs:{"outlined":"","dense":"","rows":"2","hide-details":"","label":"Status Notes"},model:{value:(_vm.dialogTourGuide.data.notes),callback:function ($$v) {_vm.$set(_vm.dialogTourGuide.data, "notes", $$v)},expression:"dialogTourGuide.data.notes"}}),_c('v-select',{staticClass:"mb-4 rounded-0",attrs:{"items":[
            { name: 'No Status', value: 0 },
            { name: 'Verified', value: 1 }
          ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":"","label":"Status"},model:{value:(_vm.dialogTourGuide.data.status),callback:function ($$v) {_vm.$set(_vm.dialogTourGuide.data, "status", _vm._n($$v))},expression:"dialogTourGuide.data.status"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogTourGuide.show = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.TOUR_GUIDE_PROCESS()}}},[_vm._v(" Save ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }